import { Component, OnInit, Input } from '@angular/core';
import { Chart, ChartType } from 'chart.js';

@Component({
  selector: 'app-odds-movement-chart',
  templateUrl: './odds-movement-chart.component.html',
  styleUrls: ['./odds-movement-chart.component.css']
})
export class OddsMovementChartComponent implements OnInit {

  @Input() title;
  @Input() yAxisTitle;
  @Input() xAxisTitle;
  @Input() data;
  
  public chartLabels = new Array();
  public chartType: ChartType = 'line';
  public chartLegend = true;
  public chartData;
  public chartOptions;

  public chartComplete = false;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = {
      scaleShowVerticalLines: false,
      responsive: false,
      scales: {
        xAxes: [{ 
          scaleLabel: {
            display: true,
            labelString: 'Date'
          },
          type: 'time',
          ticks: {
            autoSkip: true
          },
          time: {
            displayFormats: {
              'millisecond': 'MM/DD',
              'second': 'MM/DD',
              'minute': 'MM/DD',
              'hour': 'MM/DD',
              'day': 'MM/DD',
              'week': 'MM/DD',
              'month': 'MM/DD',
              'quarter': 'MM/DD',
              'year': 'MM/DD'
            },
            'unit': 'hour'
          }
         }],
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: this.yAxisTitle
          }
        }]
      }
    };

    console.log("data passed to chart: ", this.data);
    //this.chartLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July'];
    const data = {
      datasets: [{
        label: 'Home',
        data: this.data.home,
        fill: false,
        borderColor: 'limegreen',
        pointBorderColor: 'limegreen',
        pointBackgroundColor: 'limegreen',
        tension: .1,
        showLine: true,
        spanGaps: true,
        pointBorderWidth: 1,
        borderWidth: 1
      },
      {
        label: 'Away',
        data: this.data.away,
        fill: false,
        borderColor: 'dodgerblue',
        pointBorderColor: 'dodgerblue',
        pointBackgroundColor: 'dodgerblue',
        pointBorderWidth: 1,
        borderWidth: 1,
        tension: .1,
        showLine: true,
        spanGaps: true
      }]
};
    this.chartData = data.datasets;
    this.initChart();
  }

  initChart() {
    this.chartComplete = true;
  }

}
