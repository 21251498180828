import { Component } from '@angular/core';
import { ApiService } from './services/api.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'nfl-scorebooard';

  gamesThisWeek;

  constructor(private apiService: ApiService) {
      // this.apiService.getGamesForWeek(1, 2021).subscribe(data => {
      //   console.log("Got response from API: " + data);
      //   this.gamesThisWeek = data;
      // })

      // var picks = [
      //   {
      //     "gameId": "1|2021|ATL|PHI",
      //     "teamPicked": "ATL"
      //   },
      //   {
      //     "gameId": "1|2021|BUF|PIT",
      //     "teamPicked": "PIT"
      //   }
      // ]
      // this.apiService.updatePicksForUser(1, 2021, "jake", picks).subscribe(data => {
      //   console.log("Got response from API: " + data);
      // })
  }
}
