import { Component, OnInit, Input } from '@angular/core';
import { ResultsService } from 'src/app/services/results.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-results-week-picker',
  templateUrl: './results-week-picker.component.html',
  styleUrls: ['./results-week-picker.component.css']
})
export class ResultsWeekPickerComponent implements OnInit {

  @Input() default;
  weekPicker;
  weeksAvailable;
  def;
  weeksLoaded: Boolean = false;
  constructor(private dateService: DateService, private resultsService: ResultsService) {
    this.weeksAvailable = [];

    this.resultsService.currentYear.subscribe(year => {
      if (year) {
        this.weeksAvailable = [];
        if (year == 2024) {
          this.dateService.getCurrentWeek().subscribe(week => {
            if (week) {
              console.log("Current week loaded: " + week['week']);
              var currentWeek = week['week'];
              for (var i = 1; i <= currentWeek; i ++) {
                this.weeksAvailable.push(i);
              }

              this.weeksAvailable.reverse();
            }
          })
        } else {
          this.weeksAvailable = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","Wild Card","Divisional","Conference","Super Bowl"]
        }
        this.weeksLoaded = true;
        console.log("Default loaded in: " + this.default);
        this.weekPicker = this.default;
      }

    })

  }

  ngOnInit(): void {
  }

  selectWeek() {
    var weekSelected = String(this.weekPicker)
    if (weekSelected == "Wild Card") {
      weekSelected = "WildCard";
    } else if (weekSelected == "Super Bowl") {
      weekSelected = "SuperBowl";
    }

    this.resultsService.selectWeek(weekSelected);
  }

}
