import { Component, OnInit, Input } from '@angular/core';
import { TeamsService } from 'src/app/services/teams.service';
import { GamePickerService } from 'src/app/services/game-picker.service';
import { ApiService } from 'src/app/services/api.service';
import { DateService } from 'src/app/services/date.service';
import { UserService } from 'src/app/services/user.service';
import {MatDialog} from '@angular/material/dialog';
import { GameDetailViewComponent } from '../game-detail-view/game-detail-view.component';

@Component({
  selector: 'app-pick-game-row',
  templateUrl: './pick-game-row.component.html',
  styleUrls: ['./pick-game-row.component.css']
})
export class PickGameRowComponent implements OnInit {
  @Input() game;

  homeTeam;
  awayTeam;
  gameId;

  homeTeamLogo;
  homeTeamName;
  homeTeamSpread;
  homeTeamMoneyLine;

  awayTeamLogo;
  awayTeamName;
  awayTeamSpread;
  awayTeamMoneyLine;

  overUnder;

  userSelected;
  gamesOutOfDate = false;

  constructor(private dialog: MatDialog, private userService: UserService, private dateService: DateService, private apiService: ApiService, private gamePickerService: GamePickerService, private teamsService: TeamsService) { 
  }

  ngOnInit(): void {
    console.log("Game has entered row: " + this.game);
    
    this.homeTeam = this.game['homeTeam']
    this.awayTeam = this.game['awayTeam']
    this.gameId = this.game['gameId'];
  
    this.homeTeamSpread = this.game['homeTeamSpread'];
    this.homeTeamMoneyLine = this.getMoneyLine(this.game['homeTeamMoneyLine']);
    this.awayTeamSpread = this.game['awayTeamSpread'];
    this.awayTeamMoneyLine = this.getMoneyLine(this.game['awayTeamMoneyLine']);
    this.overUnder = this.game['overUnder'];

    this.homeTeamLogo = this.teamsService.getTeamLogo(this.homeTeam);
    this.homeTeamName = this.teamsService.getTeamName(this.homeTeam);
    this.awayTeamLogo = this.teamsService.getTeamLogo(this.awayTeam);
    this.awayTeamName = this.teamsService.getTeamName(this.awayTeam);
    this.userSelected = this.userService.currentUser.subscribe(user => {
      var self = this;
      setTimeout(function() {
        self.userSelected = user;
        self.clearSelectedGames();
        self.getPreSelectedGames();
      }, 100);
    })
  }

  getMoneyLine(moneyLine) {
    if (Number(moneyLine) > 0) {
      return "+" + moneyLine;
    } else {
      return moneyLine;
    }
  }

  clearSelectedGames() {
    console.log("Clearing games....");

    var gameId = this.game['gameId'];
    var gameStatus = this.game['gameStatus'];
    var homeTeamButton = document.getElementById(this.homeTeam);
    var awayTeamButton = document.getElementById(this.awayTeam);
    if (homeTeamButton) {
      homeTeamButton.className = "homeTeam";
    } else {
      console.log("Button isn't loaded yet.")
    }

    if (awayTeamButton) {
      awayTeamButton.className = "awayTeam";
    }

    if (gameStatus != "UPCOMING") {
      if (homeTeamButton && awayTeamButton) {
        homeTeamButton.className += " disabled";
        awayTeamButton.className += " disabled";
      }
      
      var gameRow = document.getElementById(gameId);
      if (gameRow) {
        gameRow.className += " disabledRow";
      }
    }
  }

  getPreSelectedGames() {
    console.log("Getting pre selected games...: " + this.userSelected);

    var usersPick = this.game[this.userSelected];
    if (usersPick) {
      console.log("User has pick: " + usersPick);
      var teamButton = document.getElementById(usersPick);
      console.log("Team button: " + teamButton);
      if (teamButton) {
        teamButton.className += " selected";
      }

      var gameStatus = this.game['gameStatus'];
      if (gameStatus != null && (gameStatus == "COMPLETE" || gameStatus == "F" || gameStatus == "F/OT")) {

        var homeTeamScore = parseInt(this.game['homeScore']);
        var awayTeamScore = parseInt(this.game['awayScore']);
        var winner = (homeTeamScore > awayTeamScore ? this.homeTeam : this.awayTeam);
        console.log("Winner of game: " + winner);

        if (usersPick == winner) {
          console.log("User is correct.");
          if (teamButton) {
            console.log("Updating correct pick.")
            teamButton.className += " correctPick";
          }
        } else {
          console.log("User is not correct.");
          if (teamButton) {
            console.log("Updating incorrect pick.");
            teamButton.className += " incorrectPick";
          }
        }
        
      }
    }
  }

  selectHomeTeam() {
    console.log("Selected home team")
    var homeButton = document.getElementById(this.homeTeam);
    var awayButton = document.getElementById(this.awayTeam);
    if (homeButton) {
      if (homeButton.classList.contains("selected")) {
        homeButton.className = "homeTeam";
      } else {
        homeButton.className += " selected";
        awayButton.className = "awayTeam";
        this.makePick(this.homeTeam);
      }
    }
  }

  selectAwayTeam() {
    console.log("Selected away team")
    var awayButton = document.getElementById(this.awayTeam);
    var homeButton = document.getElementById(this.homeTeam);
    if (awayButton) {
      if (awayButton.classList.contains("selected")) {
        awayButton.className = "awayTeam";
      } else {
        awayButton.className += " selected";
        homeButton.className = "homeTeam";
        this.makePick(this.awayTeam);
      }
    }
  }

  makePick(teamPicked) {
    this.gamePickerService.makePick(this.gameId, teamPicked);
  }

  detailView() {
    this.dialog.open(GameDetailViewComponent, {
      data: {
        //"games": this.games,
        "game": this.game,
        "gameId": this.gameId
      }
    });
  }
}
