import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { HttpClientModule } from "@angular/common/http";
import { HomePageComponent } from './components/home-page/home-page.component';
import { SelectUserComponent } from './components/select-user/select-user.component';
import { PickGamesComponent } from './components/pick-games/pick-games.component';
import { GameDetailViewComponent } from './components/game-detail-view/game-detail-view.component';
import { PickGameRowComponent } from './components/pick-game-row/pick-game-row.component';
import { FormsModule } from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { StandingsPageComponent } from './components/standings-page/standings-page.component';
import { StandingsYearSelectorComponent } from './components/standings-year-selector/standings-year-selector.component';
import { StandingsViewTableComponent } from './components/standings-view-table/standings-view-table.component';
import { StandingsRowComponent } from './components/standings-row/standings-row.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import { ResultsPageComponent } from './components/results-page/results-page.component';
import { ResultsWeekPickerComponent } from './components/results-week-picker/results-week-picker.component';
import { ResultsYearPickerComponent } from './components/results-year-picker/results-year-picker.component';
import { ResultsTableComponent } from './components/results-table/results-table.component';
import { UserStatsForTeamComponent } from './components/user-stats-for-team/user-stats-for-team.component';
import { GameOddsMovementComponent } from './components/game-odds-movement/game-odds-movement.component';
import { OddsMovementChartComponent } from './components/odds-movement-chart/odds-movement-chart.component';
import { ChartsModule } from 'ng2-charts';
import { PlayerSelectorTileComponent } from './components/player-selector-tile/player-selector-tile.component';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomePageComponent,
    SelectUserComponent,
    PickGamesComponent,
    GameDetailViewComponent,
    PickGameRowComponent,
    StandingsPageComponent,
    StandingsYearSelectorComponent,
    StandingsViewTableComponent,
    StandingsRowComponent,
    ResultsPageComponent,
    ResultsWeekPickerComponent,
    ResultsYearPickerComponent,
    ResultsTableComponent,
    UserStatsForTeamComponent,
    GameOddsMovementComponent,
    OddsMovementChartComponent,
    PlayerSelectorTileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatDialogModule,
    NoopAnimationsModule,
    MatCardModule,
    MatIconModule,
    ChartsModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }