import { Component, OnInit } from '@angular/core';
import { YearService } from 'src/app/services/year.service';

@Component({
  selector: 'app-standings-page',
  templateUrl: './standings-page.component.html',
  styleUrls: ['./standings-page.component.css']
})
export class StandingsPageComponent implements OnInit {
  yearSelected;

  constructor(private yearService: YearService) {
    this.yearService.selectYear(2024);
    this.yearService.currentYear.subscribe(year => {
      console.log("New year selected: " + year);
      this.yearSelected = year;
    })
  }

  ngOnInit(): void {
  }

}
