import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YearService {

  private currentYearSubject: BehaviorSubject<any>;
  public currentYear: Observable<any>;
  yearSelected;
  constructor() { 
    this.currentYearSubject = new BehaviorSubject<any>({});
    this.currentYear = this.currentYearSubject.asObservable();
    this.currentYearSubject.next(null);
  }

  selectYear(year) {
    console.log("Select Year called...");
    this.yearSelected = year;
    this.currentYearSubject.next(year);
  }

  getYearSelected() {
    return this.yearSelected;
  }

}
