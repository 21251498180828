<h2 class="heading">Results</h2>

<div class='top'> 
    <div class="weekPicker" *ngIf="weekSelected">
        <label>Week: </label>
        <app-results-week-picker [default]="weekSelectedDefault"></app-results-week-picker>
    </div>
    
    <div class="yearPicker" *ngIf="yearSelected">
        <label>Year: </label>
        <app-results-year-picker [default]="yearSelectedDefault"></app-results-year-picker>
    </div>
</div>


<div class="resultsTable" *ngIf="weekSelected && yearSelected">
    <app-results-table></app-results-table>
</div>