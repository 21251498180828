import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  userSelected;
  constructor() { 
    this.currentUserSubject = new BehaviorSubject<any>({});
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentUserSubject.next(null);
  }

  selectUser(user) {
    console.log("Select User called...");
    this.userSelected = user;
    this.currentUserSubject.next(user);
  }

  getSelectedUser() {
    return this.userSelected;
  }

}
