import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private cookieService: CookieService, private http: HttpClient) { }

  getGamesForWeek(week, year) {
    console.log("Grabbing games for current week: " + week);

    var weekId = week + "|" + year + '1';
  
    var url = "https://7pi01t6b48.execute-api.us-east-1.amazonaws.com/BETA/weeks/" + week + "/year/" + year;

    return this.http.get<any>(url, {});
  }

  updatePicksForUser(week, year, user, picks, potentiallyMalicious) {
    console.log("Updating picks for " + user + " for week " + week);

    var submittedPicksFor = this.cookieService.get('submittedPicksFor') ? JSON.parse(this.cookieService.get('submittedPicksFor')) : []
    var body = {
      "picks": picks,
      'potentiallyMalicious': potentiallyMalicious,
      'previousUserSubmissions': submittedPicksFor
    }

    var url = "https://7pi01t6b48.execute-api.us-east-1.amazonaws.com/BETA/weeks/" + week + "/year/" + year + "/users/" + user;

    return this.http.post<any>(url, body, {});
  }

  getCurrentStandings(year) {
    console.log("Grabbing standings for: " + year);
  
    var url = "https://7pi01t6b48.execute-api.us-east-1.amazonaws.com/BETA/standings/" + year;

    return this.http.get<any>(url, {});
  }

  getCurrentWeek() {
    console.log("Grabbing current week");
  
    var url = "https://7pi01t6b48.execute-api.us-east-1.amazonaws.com/BETA/currentweek/";

    return this.http.get<any>(url, {});
    // return {
    //   "week": 1,
    //   "year": 2021
    // }
  }

  getUserStatsForTeam(user, homeTeam, awayTeam) {
    console.log("Grabbing user stats for team");
  
    var url = "https://7pi01t6b48.execute-api.us-east-1.amazonaws.com/BETA/players/stats";

    var body = {
      "player": user,
      "homeTeam": homeTeam,
      "awayTeam": awayTeam
    }

    return this.http.post<any>(url, body, {});
  }

  getOddsForGame(gameId) {
    console.log("Grabbing odds for game");
  
    var url = "https://7pi01t6b48.execute-api.us-east-1.amazonaws.com/BETA/games/odds";

    var body = {
      "gameId": gameId
    }

    return this.http.post<any>(url, body, {});
  }
}
