import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  weekSelected;
  yearSelected;

  private currentWeekSubject: BehaviorSubject<any>;
  private currentYearSubject: BehaviorSubject<any>;

  public currentWeek: Observable<any>;
  public currentYear: Observable<any>;

  constructor() { 
    this.currentWeekSubject = new BehaviorSubject<any>({});
    this.currentYearSubject = new BehaviorSubject<any>({});

    this.currentWeek = this.currentWeekSubject.asObservable();
    this.currentYear = this.currentYearSubject.asObservable();

    this.currentWeekSubject.next(null);
    this.currentYearSubject.next(null);
  }

  selectWeek(week) {
    this.weekSelected = week;
    this.currentWeekSubject.next(week);
  }

  selectYear(year) {
    this.yearSelected = year;
    this.currentYearSubject.next(year);
  }

  getWeek() {
    return this.weekSelected;
  }

  getYear() {
    return this.yearSelected;
  }
}
