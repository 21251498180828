import { Component, OnInit } from '@angular/core';
import { YearService } from 'src/app/services/year.service';

@Component({
  selector: 'app-standings-year-selector',
  templateUrl: './standings-year-selector.component.html',
  styleUrls: ['./standings-year-selector.component.css']
})
export class StandingsYearSelectorComponent implements OnInit {

  yearPicker;
  yearsAvailable
  constructor(private yearService: YearService) { }

  ngOnInit(): void {
    this.yearsAvailable = ["2024", "2023", "2022", "2021", "2020"]
    this.yearPicker = "2024"
  }

  selectYear() {
    console.log("Year selected: " + String(this.yearPicker));
    this.yearService.selectYear(String(this.yearPicker));
  }

}
