import { Component, OnInit } from '@angular/core';
import { ResultsService } from 'src/app/services/results.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-results-page',
  templateUrl: './results-page.component.html',
  styleUrls: ['./results-page.component.css']
})
export class ResultsPageComponent implements OnInit {

  yearSelected: Boolean = false;
  weekSelected: Boolean = false;
  weekSelectedDefault;
  yearSelectedDefault;
  constructor(private dateService: DateService, private resultsService: ResultsService) { 
    this.dateService.getCurrentWeek().subscribe(data => {
      console.log("Data from date service: " + data['week']);
      this.weekSelectedDefault = data['week']
      this.yearSelectedDefault = data['year']
      this.yearSelected = true;
      this.weekSelected = true;

      this.resultsService.selectWeek(data['week'])
      this.resultsService.selectYear(data['year'])
    })
  }

  ngOnInit(): void {
  }

}
