import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private http: HttpClient) { }

  getCurrentWeek() {
    console.log("Grabbing current week");
  
    var url = "https://7pi01t6b48.execute-api.us-east-1.amazonaws.com/BETA/currentweek/";

    return this.http.get<any>(url, {});
    // return Promise{
    //   "week": 1,
    //   "year": 2021
    // }
  }

}
