import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { YearService } from 'src/app/services/year.service';
import { PlayerService } from 'src/app/services/player.service';

@Component({
  selector: 'app-standings-view-table',
  templateUrl: './standings-view-table.component.html',
  styleUrls: ['./standings-view-table.component.css']
})
export class StandingsViewTableComponent implements OnInit {

  yearSelected;
  loadedStandings;
  currentStandings;
  lastWeekStandings;
  lastWeekRankFound: Boolean;
  thisWeekStandings;

  constructor(private playerService: PlayerService, private yearService: YearService, private apiService: ApiService) { 
    this.loadedStandings = [];
    this.yearService.currentYear.subscribe(year => {
      console.log("New year selected: " + year);
      this.currentStandings = [];
      this.loadedStandings = [];
      this.lastWeekStandings =[];
      this.apiService.getCurrentStandings(year).subscribe(data => {
        console.log("Received current standings: " + data);
        this.currentStandings = data;
        this.loadStandings();
      })
    })
  }

  loadStandings() {
    this.lastWeekStandings = this.currentStandings['lastWeek']
    this.thisWeekStandings = this.currentStandings['thisWeek']

    for(var i = 0; i < this.thisWeekStandings.length; i ++) {
      var player = this.thisWeekStandings[i];
      var playerName = player['player'];
      var lastWeekRank = this.getPlayerRankLastWeek(playerName);
      console.log("Last weeek......" + lastWeekRank)
      //var rank = player['rank'];
      var wins = parseInt(player['wins']);
      var losses = parseInt(player['losses']);
      console.log("Player: " + player);
      var gamesPlayed = wins + losses;
      var percent = Math.round((wins/gamesPlayed) * 100);
      console.log("Wins; " + wins+  ", games played: " + gamesPlayed + ", percent: " + percent);
      var image = this.playerService.getPlayerImage(playerName);
      var obj = {
        "player": playerName,
        //"rank": rank,
        "lastWeekRank": lastWeekRank,
        "wins": wins,
        "losses": losses,
        "image": image,
        "percent": percent
      }
      this.loadedStandings.push(obj);
    }
  }

  getPlayerRankLastWeek(playerToFind) {
    console.log("Called function")
    if (this.lastWeekStandings.length > 0) {
      for(var i = 0; i < this.lastWeekStandings.length; i ++) {
        var player = this.lastWeekStandings[i];
        var playerName = player['player'];
        if (playerName == playerToFind) {
          return i + 1;
        }
      }
    }

    return -1;
  }

  ngOnInit(): void {
  }

}
