<div class="playerDropdown">
    <button id="playerDropdownButton" (click)="showPlayers()">{{playerDropdownText}}</button>
</div>

<div class="playerPicker hidden teamsList" id="playerPicker" *ngIf="playersAvailable">
    <div class="list">
        <mat-grid-list cols="4">
            <mat-grid-tile *ngFor="let player of playersAvailable">
                <button mat-raised-button (click)="togglePlayer(player)" class='tile' id={{player.playerName}}>
                    <app-player-selector-tile title={{player.playerName}} image={{player.playerImage}}></app-player-selector-tile>
                </button>
            </mat-grid-tile>
        </mat-grid-list>    
    </div>
</div>