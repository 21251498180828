<div class='oddsMovementChart' *ngIf='chartComplete'>
    <div style="display: block;">
        <canvas baseChart id="chart"
                [datasets]="chartData"
                [labels]="chartLabels"
                [options]="chartOptions"
                [legend]="chartLegend"
                [chartType]="chartType"
                height="250"
                width="250">
        </canvas>
    </div>
</div>