<div class="playerStatsContainer">
    <div class="winsHeader">W</div>
    <div class="lossesHeader">L</div>
    <div class="tiesHeader">T</div>
    <div class="teamLogo">
        <img class="teamLogo" src="{{teamLogo}}">
    </div>
    <div class="teamWins">{{teamWins}}</div>
    <div class="teamLosses">{{teamLosses}}</div>
    <div class="teamTies">{{teamTies}}</div>
    <div class="playerLogo">
        <img mat-card-avatar class="playerPic" src="{{playerLogo}}">
    </div>
    <div class="playerWins">{{playerWins}}</div>
    <div class="playerLosses">{{playerLosses}}</div>
    <div class="playerTies">{{playerTies}}</div>
  </div>