<div class="outer">
    <div class="inner">
        <table>
            <tr>
                <th class="filler"></th>
                <td *ngFor="let logo of playersLogos">
                    <img mat-card-avatar class="playerPic" src="{{logo}}">
                </td>
            </tr>
            <tr *ngFor="let game of dataForTable;" >
                <th>
                    <div class="container">
                        <div class="homeTeam">
                            <img mat-card-avatar class="teamPic" src="{{game.homeTeamLogo}}">
                        </div>
                        <div class="awayTeam">
                            <img mat-card-avatar class="teamPic" src="{{game.awayTeamLogo}}">
                        </div>
                        <div class="vs">vs.</div>
                    </div>
                </th>
        
                <td *ngFor="let player of game.playerGuesses">
                    <div *ngIf="game.gameFinished && player.playerCorrect" class="correctGuess">
                        <img mat-card-avatar class="teamPic playerGuess" src="{{player.playerGuessLogo}}">
                    </div>
                    <div *ngIf="game.gameFinished && !player.playerCorrect && !player.playerTie" class="incorrectGuess">
                        <img mat-card-avatar class="teamPic playerGuess" src="{{player.playerGuessLogo}}" *ngIf="player.playerGuess">
                    </div>
                    <div *ngIf="game.gameFinished && !player.playerCorrect && player.playerTie" class="tieGuess">
                        <img mat-card-avatar class="teamPic playerGuess" src="{{player.playerGuessLogo}}" *ngIf="player.playerGuess">
                    </div>
                    <div *ngIf="!game.gameFinished">
                        <img mat-card-avatar class="teamPic playerGuess" src="{{player.playerGuessLogo}}">
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
<!-- <table>
    <tr>
        <td class="filler"></td>
        <td *ngFor="let logo of playersLogos">
            <img mat-card-avatar class="playerPic" src="{{logo}}">
        </td>
    </tr>
    <ng-container *ngIf="dataForTableLoaded">
        <tr *ngFor="let game of dataForTable;" >

            <td class="gameColumnData">
                <div class="game">
                    <div class="container">
                        <div class="homeTeam">
                            <img mat-card-avatar class="teamPic" src="{{game.homeTeamLogo}}">
                        </div>
                        <div class="awayTeam">
                            <img mat-card-avatar class="teamPic" src="{{game.awayTeamLogo}}">
                        </div>
                        <div class="vs">vs.</div>
                    </div>
                </div>
            </td>
    
            <td *ngFor="let player of game.playerGuesses">
                <div *ngIf="game.gameFinished && player.playerCorrect" class="correctGuess">
                    <img mat-card-avatar class="playerPic" src="{{player.playerGuessLogo}}">
                </div>
                <div *ngIf="game.gameFinished && !player.playerCorrect" class="incorrectGuess">
                    <img mat-card-avatar class="playerPic" src="{{player.playerGuessLogo}}" *ngIf="player.playerGuess">
                </div>
                <div *ngIf="!game.gameFinished">
                    <img mat-card-avatar class="playerPic" src="{{player.playerGuessLogo}}">
                </div>
            </td>
        </tr>
    </ng-container>
</table>
 -->
