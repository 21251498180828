import { Component, OnInit, Input } from '@angular/core';
import { PlayerService } from 'src/app/services/player.service';
import { TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'app-user-stats-for-team',
  templateUrl: './user-stats-for-team.component.html',
  styleUrls: ['./user-stats-for-team.component.css']
})
export class UserStatsForTeamComponent implements OnInit {

  @Input() year;
  @Input() playerName;
  @Input() teamName;
  @Input() teamWins;
  @Input() teamLosses;
  @Input() teamTies;
  @Input() playerWins;
  @Input() playerLosses;
  @Input() playerTies;

  playerLogo;
  teamLogo;

  constructor(private playerService: PlayerService, private teamsService: TeamsService) {
  }

  ngOnInit(): void {
    console.log("Player name: ", this.playerName);
    console.log("Player wins: ", this.playerWins);
    this.playerLogo = this.playerService.getPlayerImage(this.playerName);

    this.teamLogo = this.teamsService.getTeamLogo(this.teamName);
  }

}
