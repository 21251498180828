import { Component, OnInit } from '@angular/core';
import { ResultsService } from 'src/app/services/results.service';
import { ApiService } from 'src/app/services/api.service';
import { PlayerService } from 'src/app/services/player.service';
import { TeamsService } from 'src/app/services/teams.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.css']
})
export class ResultsTableComponent implements OnInit {

  weekSelected;
  yearSelected;

  gamesForWeek;

  dataForTable;
  dataForTableLoaded;

  playersForWeek;
  playersLogos;

  constructor(private userService: UserService, private teamsService: TeamsService, private playerService: PlayerService, private apiService: ApiService, private resultsService: ResultsService) { 
    this.resultsService.currentYear.subscribe(year => {
      this.playersForWeek = [];
      this.playersLogos = [];
      this.yearSelected = year;
      this.playerService.getAvailablePlayers(year).subscribe(data => {
        this.playersForWeek = data;

        var currentUser = this.userService.getSelectedUser();
        if (currentUser) {
          var currentIndex = this.playersForWeek.indexOf(currentUser);
          if (currentIndex > -1) {
            this.playersForWeek.splice(currentIndex, 1);
          }
          this.playersForWeek.splice(0, 0, currentUser);
        }

        
        for (var i = 0; i < this.playersForWeek.length; i ++) {
          var player = this.playersForWeek[i];
          this.playersLogos.push(this.playerService.getPlayerImage(player));
        }


        //this.resultsService.selectWeek("1");
        this.resultsService.currentWeek.subscribe(week => {
          this.weekSelected = week;
          console.log("Week Selected: " + this.weekSelected);
          this.apiService.getGamesForWeek(this.weekSelected, parseInt(this.yearSelected)).subscribe(data => {
            //console.log('games for week loaded: ' + data) 
            var unsortedGames = data;

            this.gamesForWeek = unsortedGames.sort(function(game1, game2) {
              var game1StartTime = new Date(game1['gameStartTime']);
              var game2StartTime = new Date(game2['gameStartTime']);
              
              return (game1StartTime > game2StartTime) ? 1 : -1;
            });

            this.loadTable();
          })
        })
      })
    })
  }

  loadTable() {
    this.dataForTable = [];
    for (var i = 0; i < this.gamesForWeek.length; i ++) {
      var game = this.gamesForWeek[i];
      var homeTeam = game['homeTeam'];
      var awayTeam = game['awayTeam'];
      var homeScore = parseInt(game['homeScore']);
      var awayScore = parseInt(game['awayScore']);
      var homeTeamLogo = this.teamsService.getTeamLogo(homeTeam);
      var awayTeamLogo = this.teamsService.getTeamLogo(awayTeam);

      var winner = (homeScore > awayScore ? homeTeam : (awayScore > homeScore ? awayTeam : 'TIE')).trim();
      var tieGame = homeScore == awayScore;
      //console.log("Home: " + homeTeam + ",score: " + homeScore + ", Away: " + awayTeam + ", score: " + awayScore);
      
      //console.log("Winner: " + winner);

      var playerGuesses = [];
      var row = {
        'homeTeam': homeTeam,
        'homeTeamLogo': homeTeamLogo,
        'awayTeam': awayTeam,
        'awayTeamLogo': awayTeamLogo,
        'homeScore': homeScore,
        'awayScore': awayScore,
        'gameFinished': (game['gameStatus'] && (game['gameStatus'] == 'COMPLETE' || game['gameStatus'] == 'F' || game['gameStatus'] == 'F/OT') ? true : false)
      }

      for (var x = 0; x < this.playersForWeek.length; x ++) {
        var player = this.playersForWeek[x];
        var playerGuess = game[player];
        
        var playerObject = {
          'player': player
        }

        if (tieGame) {
          playerObject['playerTie'] = true;
        }
        
        if (playerGuess) {
          playerGuess = playerGuess.trim();
          //console.log("Player guess: " + playerGuess);
          //console.log("Winner: " + winner);
          //console.log("Guess == winner? " + (winner == playerGuess));
          playerObject['playerGuess'] = playerGuess;
          playerObject['playerGuessLogo'] = (playerGuess == homeTeam ? homeTeamLogo : awayTeamLogo);
          playerObject['playerCorrect'] = (playerGuess == winner);
        } else {
          playerObject['playerGuess'] = "";
          playerObject['playerCorrect'] = false;
        }

        playerGuesses.push(playerObject);
      }

      row['playerGuesses'] = (playerGuesses);
      this.dataForTable.push(row);
    }

    this.dataForTableLoaded = true;
  }
  ngOnInit(): void {
  }

}