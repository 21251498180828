<div class="container">
    <div class="playerSelector">
        <app-select-user></app-select-user>
    </div>
    <div class="gameSelector" *ngIf="userSelected">
        <app-pick-games (gamesLoadedEvent)="gamesLoadedEvent($event)"></app-pick-games>
    </div>
    <div class="savePicks" *ngIf="userSelected && gamesLoaded">
        <button (click)="submitPicksForUser()" id="submitPicksButton" class="submitPicksButton">
            Save Picks
        </button>
    </div>
</div>