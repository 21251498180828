import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-standings-row',
  templateUrl: './standings-row.component.html',
  styleUrls: ['./standings-row.component.css']
})
export class StandingsRowComponent implements OnInit {

  @Input() rank;
  @Input() lastWeek;
  @Input() name;
  @Input() wins;
  @Input() losses;
  @Input() image;
  @Input() percent;

  difference;
  movedUp: Boolean;
  movedDown: Boolean;
  stayedSame: Boolean;
  constructor() { }

  ngOnInit(): void {
    if (this.lastWeek > 1) {
      console.log("Here??????")
      this.difference = this.lastWeek - this.rank;
      console.log("last week loaded: "+ this.difference);
      if (this.difference > 0) {
        this.movedUp = true;
        console.log("Moved up");
      } else if (this.difference < 0) {
        this.movedDown = true;
        console.log("Moved down");
      } else {
        this.stayedSame = true;
        console.log("Stayed same");
      }
    } else {
      this.stayedSame = true;
    }
    
  }

}
