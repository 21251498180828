import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-odds-movement',
  templateUrl: './game-odds-movement.component.html',
  styleUrls: ['./game-odds-movement.component.css']
})
export class GameOddsMovementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
