import { Component, OnInit } from '@angular/core';
import { PlayerService } from '../../services/player.service'
import { UserService } from 'src/app/services/user.service';
import { DateService } from 'src/app/services/date.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.css']
})
export class SelectUserComponent implements OnInit {

  playersAvailable;
  playerPicker;
  default;

  selectedPlayer;
  playerDropdownText;

  constructor(private cookieService: CookieService, private dateService: DateService, private userService: UserService, private playerService: PlayerService) { 
  }

  ngOnInit(): void {
    var playerChosen = this.cookieService.get('playerChosen');
    console.log("Player chosen: " + playerChosen);

    if (playerChosen) {
      this.playerDropdownText = "Player chosen: " + playerChosen;
      this.userService.selectUser(playerChosen);
    } else {
      this.playerDropdownText = "Select Player:";
    }

    console.log("Attempting to load current players");
    this.dateService.getCurrentWeek().subscribe(data => {
      console.log("Year loaded: "+ data['year'])
      this.playerService.getAvailablePlayers(data['year']).subscribe(playerData => {
        var playersAvailable = [];
        playerData.forEach(player => {
          playersAvailable.push({
            "playerName": player,
            "playerImage": this.playerService.getPlayerImage(player)
          })
        });
        this.playersAvailable = playersAvailable;
      })
      console.log("Players loaded: " + this.playersAvailable);
    });
  }

  showPlayers() {
    //show the player dropdown
    var playerPicker = document.getElementById("playerPicker");
    if (playerPicker) {
      playerPicker.className = "playerPicker teamList visible";
    }
  }

  togglePlayer(player) {
    console.log("Player selected: " + player.playerName);
    this.userService.selectUser(player.playerName);
    this.playerDropdownText = "Player chosen: " + player.playerName;

    //hide the player dropdown
    var playerPicker = document.getElementById("playerPicker");
    if (playerPicker) {
      playerPicker.className = "playerPicker teamList hidden";
    }

    this.cookieService.set('playerChosen', player.playerName);
  }

}
