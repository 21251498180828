import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { GamePickerService } from 'src/app/services/game-picker.service';
import { ApiService } from 'src/app/services/api.service';
import { DateService } from 'src/app/services/date.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  userSelected;
  week;
  year;
  gamesLoaded;
  constructor(private cookieService: CookieService, private dateService: DateService, private apiService: ApiService, private gamePickerService: GamePickerService, private userService: UserService) { 
    this.dateService.getCurrentWeek().subscribe(data => {
      this.week = data['week'];
      this.year = data['year'];
    });
  }

  ngOnInit(): void {
    this.userService.currentUser.subscribe(user => {
      console.log("New user selected from user service: " + user);
      this.userSelected = user;
      this.gamePickerService.clearPicks();
    })
  }

  gamesLoadedEvent(event) {
    this.gamesLoaded = true;
  }

  submitPicksForUser() {
    var submitButton = document.getElementById('submitPicksButton');
    if (submitButton) {
      submitButton.className += " disabledSubmit"
    }

    var actualPicksArray = []
    var picks = this.gamePickerService.getPicks();
    Object.keys(picks).forEach(function(key) {
        var gameId = key;
        var teamPicked = picks[key];
        var obj = {
          "gameId": gameId,
          "teamPicked": teamPicked
        }
        actualPicksArray.push(obj);
    })

    var potentiallyMalicious = false;
    var submittedPicksFor = this.cookieService.get('submittedPicksFor');
    if (submittedPicksFor) {
      var submittedList = JSON.parse(submittedPicksFor);
      if (submittedList.length == 0) {
        console.log("First user submission: " + this.userSelected);
        submittedList.push(this.userSelected);
        this.cookieService.set('submittedPicksFor', JSON.stringify(submittedList));
      } else if (submittedList.length == 1 && submittedList[0] === this.userSelected) {
        console.log("Valid user submission");
      } else {
        console.log("Potentially malicious submission.");
        var potentiallyMalicious = true;
        var userInList = false;
        submittedList.forEach((user) => {
          if (user === this.userSelected) {
            console.log("Has submitted for this user before.");
            userInList = true;
          }
        })
  
        if (!userInList) {
          submittedList.push(this.userSelected);
        }
        this.cookieService.set('submittedPicksFor', JSON.stringify(submittedList));
      }
    }


    console.log("Attempting to submit picks: " + picks);
    this.apiService.updatePicksForUser(this.week, this.year, this.userSelected, actualPicksArray, potentiallyMalicious).subscribe(data => {
      console.log("Submitting picks for user successful: " + data);
      window.alert("Picks saved successfully.");

      var list = []
      list.push(this.userSelected);
      this.cookieService.set('submittedPicksFor', JSON.stringify(list));

      if (submitButton) {
        submitButton.className = "submitPicksButton";
      }
    })
  }

}
