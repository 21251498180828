import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private http: HttpClient) { }

  getAvailablePlayers(year) {
    console.log("Grabbing players for: " + year);
  
    var url = "https://7pi01t6b48.execute-api.us-east-1.amazonaws.com/BETA/players/" + year;

    return this.http.get<any>(url, {});
  }

  getPlayerImage(playerName) {
    var lower = playerName.toLowerCase();
    return "../assets/images/" + lower + ".jpg";
  }
}
