import { Component, OnInit, Input } from '@angular/core';
import { ResultsService } from 'src/app/services/results.service';

@Component({
  selector: 'app-results-year-picker',
  templateUrl: './results-year-picker.component.html',
  styleUrls: ['./results-year-picker.component.css']
})
export class ResultsYearPickerComponent implements OnInit {

  @Input() default;
  yearPicker;
  yearsAvailable;
  def;
  constructor(private resultsService: ResultsService) {
    this.yearsAvailable = ["2024", "2023", "2022", "2021", "2020"]
  }

  ngOnInit(): void {
  }

  selectYear() {
    this.resultsService.selectYear(String(this.yearPicker));
  }
}
