import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service'
import { DateService } from 'src/app/services/date.service';
import { UserService } from 'src/app/services/user.service';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pick-games',
  templateUrl: './pick-games.component.html',
  styleUrls: ['./pick-games.component.css']
})
export class PickGamesComponent implements OnInit {

  gamesThisWeek;
  currentWeek;
  currentYear;

  @Output() gamesLoadedEvent = new EventEmitter<boolean>();

  constructor(private userService: UserService, private dateService: DateService, private apiService: ApiService) {

      var dates = {
        "Thursday": 1,
        "Saturday": 2,
        "Sunday": 3,
        "Monday": 4
      };

      this.dateService.getCurrentWeek().subscribe(data => {
        this.currentWeek = data['week'];
        this.currentYear = data['year'];
        //this.currentWeek = 1;
        //this.currentYear = 2021;
        console.log("Current week: " + this.currentWeek);
      
        this.apiService.getGamesForWeek(this.currentWeek, this.currentYear).subscribe(data => {
          console.log("Received games this week: ",data)
          var unsortedGames = data;
          this.gamesThisWeek = unsortedGames.sort(function(game1, game2) {
            var game1StartTime = new Date(game1['gameStartTime']);
            var game2StartTime = new Date(game2['gameStartTime']);
            
            return (game1StartTime > game2StartTime) ? 1 : -1;
          });
          //this.gamesThisWeek = data;
          this.picksLoaded();
        })})
   }

   picksLoaded() {
    this.gamesLoadedEvent.emit(true);
   }

  ngOnInit(): void {
  }

}
