import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  teams;

  constructor() {

    
    this.teams = {
        "ARI": {
          "logo": "../assets/images/arizonaCardinals.png",
          "teamName": "Arizona Cardinals"
        },
        "ATL": {
          "logo": "../assets/images/atlantaFalcons.png",
          "teamName": "Atlanta Falcons"
        },
        "BAL": {
          "logo": "../assets/images/baltimoreRavens.png",
          "teamName": "Baltimore Ravens"
        },
        "BUF": {
          "logo": "../assets/images/buffaloBills.png",
          "teamName": "Buffalo Bills"
        },
        "CAR": {
          "logo": "../assets/images/carolinaPanthers.png",
          "teamName": "Carolina Panthers"
        },
        "CHI": {
          "logo": "../assets/images/chicagoBears.png",
          "teamName": "Chicago Bears"
        },
        "CIN": {
          "logo": "../assets/images/cincinnatiBengals.png",
          "teamName": "Cincinnati Bengals"
        },
        "CLE": {
          "logo": "../assets/images/clevelandBrowns.png",
          "teamName": "Cleveland Browns"
        },
        "DAL": {
          "logo": "../assets/images/dallasCowboys.png",
          "teamName": "Dallas Cowboys"
        },
        "DEN": {
          "logo": "../assets/images/denverBroncos.png",
          "teamName": "Denver Broncos"
        },
        "DET": {
          "logo": "../assets/images/detroitLions.png",
          "teamName": "Detroit Lions"
        },
        "GB": {
          "logo": "../assets/images/greenBayPackers.png",
          "teamName": "Green Bay Packers"
        },
        "HOU": {
          "logo": "../assets/images/houstonTexans.png",
          "teamName": "Houston Texans"
        },
        "IND": {
          "logo": "../assets/images/indianapolisColts.png",
          "teamName": "Indianapolis Colts"
        },
        "JAX": {
          "logo": "../assets/images/jacksonvilleJaguars.png",
          "teamName": "Jacksonville Jaguars"
        },
        "KC": {
          "logo": "../assets/images/kansasCityChiefs.png",
          "teamName": "Kansas City Chiefs"
        },
        "LV": {
          "logo": "../assets/images/lasVegasRaiders.png",
          "teamName": "Las Vegas Raiders"
        },
        "LAC": {
          "logo": "../assets/images/losAngelesChargers.png",
          "teamName": "Los Angeles Chargers"
        },
        "LAR": {
          "logo": "../assets/images/losAngelesRams.png",
          "teamName": "Los Angeles Rams"
        },
        "MIA": {
          "logo": "../assets/images/miamiDolphins.png",
          "teamName": "Miami Dolphins"
        },
        "MIN": {
          "logo": "../assets/images/minnesotaVikings.png",
          "teamName": "Minnesota Vikings"
        },
        "NE": {
          "logo": "../assets/images/newEnglandPatriots.png",
          "teamName": "New England Patriots"
        },
        "NO": {
          "logo": "../assets/images/newOrleansSaints.png",
          "teamName": "New Orleans Saints"
        },
        "NYG": {
          "logo": "../assets/images/newYorkGiants.png",
          "teamName": "New York Giants"
        },
        "NYJ": {
          "logo": "../assets/images/newYorkJets.png",
          "teamName": "New York Jets"
        },
        "PIT": {
          "logo": "../assets/images/pittsburghSteelers.png",
          "teamName": "Pittsburgh Steelers"
        },
        "PHI": {
          "logo": "../assets/images/philadelphiaEagles.png",
          "teamName": "Philadelphia Eagles"
        },
        "SF": {
          "logo": "../assets/images/sanFrancisco49ers.png",
          "teamName": "San Francisco 49ers"
        },
        "SEA": {
          "logo": "../assets/images/seattleSeahawks.png",
          "teamName": "Seattle Seahawks"
        },
        "TB": {
          "logo": "../assets/images/tampaBayBuccaneers.png",
          "teamName": "Tampa Bay Buccaneers"
        },
        "TEN": {
          "logo": "../assets/images/tennesseeTitans.png",
          "teamName": "Tennessee Titans"
        },
        "WAS": {
          "logo": "../assets/images/washingtonFootballTeam.png",
          "teamName": "Washington Redskins"
        }
      }
    }

    getTeamLogo(team) {
      return this.teams[team]['logo']
    }

    getTeamName(team) {
      return this.teams[team]['teamName']
    }
}
