<div class="container">
    <div class="homeTeamLogo">
        <img class="teamLogo" src="{{homeTeamLogo}}">
    </div>
    <div class="awayTeamLogo">
        <img class="teamLogo" src="{{awayTeamLogo}}">
    </div>
    <div class="homeTeamName">{{homeTeamName}}</div>
    <div class="awayTeamName">{{awayTeamName}}</div>

    <div class="homeTeamBetting">{{homeTeamSpread}} ({{homeTeamMoneyLine}})</div>
    <div class="awayTeamBetting">{{awayTeamSpread}} ({{awayTeamMoneyLine}})</div>

    <div *ngIf="userStatsForTeam" class="playerStatsYearSelector" style="margin-top: 10px;">
        <h4>Stats by Season:</h4>
        <div *ngFor="let year of yearsAvailable" style="display: inline-block; margin-left: 20px; margin-right: 20px;">
            <div (click)=toggleYear(year) id="button{{year}}" class="notSelectedYear">
                {{year}}
            </div>
        </div>
    </div>

    <div *ngIf="userStatsForTeam" class="homePlayerStats" style="margin-top: 10px;">
        <div *ngFor="let year of yearsAvailable" class="{{year}} hidden">
            <app-user-stats-for-team [playerName]="currentPlayer" [teamName]=homeTeam [year]="year" [teamWins]="userStatsForTeam.homeTeamStats[year].team.wins" [teamLosses]=userStatsForTeam.homeTeamStats[year].team.losses [teamTies]=userStatsForTeam.homeTeamStats[year].team.ties
                [playerWins]=userStatsForTeam.homeTeamStats[year].player.wins [playerLosses]=userStatsForTeam.homeTeamStats[year].player.losses [playerTies]=userStatsForTeam.homeTeamStats[year].player.ties></app-user-stats-for-team>
        </div>
    </div>

    <div *ngIf="userStatsForTeam" class="awayPlayerStats" style="margin-top: 10px;">
        <div *ngFor="let year of yearsAvailable" class="{{year}} hidden">
            <app-user-stats-for-team [playerName]="currentPlayer" [teamName]=awayTeam [year]="year" [teamWins]=userStatsForTeam.awayTeamStats[year].team.wins [teamLosses]=userStatsForTeam.awayTeamStats[year].team.losses [teamTies]=userStatsForTeam.awayTeamStats[year].team.ties
                [playerWins]=userStatsForTeam.awayTeamStats[year].player.wins [playerLosses]=userStatsForTeam.awayTeamStats[year].player.losses [playerTies]=userStatsForTeam.awayTeamStats[year].player.ties></app-user-stats-for-team>
        </div>
    </div>

    <div *ngIf="oddsForGame && moneyLineMovement" class="oddsSelector" style="display: inline-block; margin-top: 20px;">
        <h4>Line Movement Trends:</h4>
        <div (click)=toggleMoneyLine() id="moneyLineSelector" class="selectedOdds" style="display: inline-block; margin-right: 10px; padding: 10px;">
            Money Line
        </div>

        <div (click)=toggleSpread() id="spreadSelector" class="notSelectedOdds" style="display: inline-block; margin-left: 10px; padding: 10px;">
            Spread
        </div>
    </div>

    <div *ngIf="oddsForGame && moneyLineMovement" class="oddsMovement">
        <app-odds-movement-chart id="moneyLineChart" class="visible" [title]="MLMovement" [xAxisTitle]="date" [yAxisTitle]=moneyLineYAxisLabel [data]=moneyLineMovement> </app-odds-movement-chart>
        <app-odds-movement-chart id="spreadChart" class="hidden" [title]="MLMovement" [xAxisTitle]="date" [yAxisTitle]=spreadYAxisLabel [data]=spreadMovement> </app-odds-movement-chart>
    </div> 

    <div class="homeTeamLogo2">
        <img class="teamLogo" src="{{homeTeamLogo}}">
    </div>
    <div class="awayTeamLogo2">
        <img class="teamLogo" src="{{awayTeamLogo}}">
    </div>
    <div class="homeTeamName2">{{homeTeamName}}</div>
    <div class="awayTeamName2">{{awayTeamName}}</div>
    <div class="homeTeamList">
        <div *ngFor="let player of homeTeamList;">
            <img mat-card-avatar class="playerPic" src="{{player}}">
        </div>
    </div>
    <div class="awayTeamList">
        <div *ngFor="let player of awayTeamList;">
            <img mat-card-avatar class="playerPic" src="{{player}}">
        </div>
    </div>
    <div class="middle">
      <div class="gameDate">{{gameDate}}</div>
      <div class="vs">vs</div>
    </div>
</div>