import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GamePickerService {

  currentPicks;
  constructor() { 
    this.currentPicks = {};
  }

  makePick(gameId, teamPicked) {
    this.currentPicks[gameId] = teamPicked;
  }

  getPicks() {
    return this.currentPicks;
  }

  clearPicks() {
    this.currentPicks = {};
  }
}
