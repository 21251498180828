import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { PlayerService } from 'src/app/services/player.service';
import { TeamsService } from 'src/app/services/teams.service';
import { DateService } from 'src/app/services/date.service';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { element } from 'protractor';

@Component({
  selector: 'app-game-detail-view',
  templateUrl: './game-detail-view.component.html',
  styleUrls: ['./game-detail-view.component.css']
})
export class GameDetailViewComponent implements OnInit {

  //games;
  game;
  gameId;

  homeTeam;
  awayTeam;

  homeTeamList;
  awayTeamList;

  homeTeamLogo;
  awayTeamLogo;

  homeTeamName;
  awayTeamName;

  homeTeamSpread;
  homeTeamMoneyLine;
  awayTeamSpread;
  awayTeamMoneyLine;

  gameDate;

  players;

  oddsForGame;
  moneyLineMovement;
  spreadMovement;

  userStatsForTeam;

  currentPlayer;

  yearsAvailable;

  moneyLineYAxisLabel = "Money Line";
  spreadYAxisLabel = "Spread";

  constructor(private userService: UserService, private apiService: ApiService, private dateService: DateService, private teamsService: TeamsService, private playerService: PlayerService, @Inject(MAT_DIALOG_DATA) public data: any) {
    //this.games = data['games'];
    this.game = data['game'];
    this.homeTeam = this.game['homeTeam'];
    this.awayTeam = this.game['awayTeam'];

    this.homeTeamSpread = this.game['homeTeamSpread'];
    this.homeTeamMoneyLine = this.getMoneyLine(this.game['homeTeamMoneyLine']);
    this.awayTeamSpread = this.game['awayTeamSpread'];
    this.awayTeamMoneyLine = this.getMoneyLine(this.game['awayTeamMoneyLine']);

    //this.gameId = data['gameId'];
    this.dateService.getCurrentWeek().subscribe(data => {
      this.playerService.getAvailablePlayers(data['year']).subscribe(playerData => {
        this.players = playerData;

        this.homeTeamLogo = this.teamsService.getTeamLogo(this.homeTeam);
        this.homeTeamName = this.teamsService.getTeamName(this.homeTeam);
        this.awayTeamLogo = this.teamsService.getTeamLogo(this.awayTeam);
        this.awayTeamName = this.teamsService.getTeamName(this.awayTeam);

        this.gameDate = this.game['weekDay'];

        for (var x = 0; x < this.players.length; x ++) {
          var player = this.players[x];
          var playerImage = this.playerService.getPlayerImage(player);
          var teamPicked = this.game[player];
          if (teamPicked && teamPicked == this.homeTeam) {
            this.homeTeamList.push(playerImage);
          } else if (teamPicked && teamPicked == this.awayTeam) {
            this.awayTeamList.push(playerImage);
          }
        }
      })
    })
    this.homeTeamList = [];
    this.awayTeamList = [];

    this.apiService.getOddsForGame(this.game['gameId']).subscribe(data => {
      console.log("Odds for game: ", data);
      this.oddsForGame = data.odds;

      var moneyLineMovement = {
        'home': [],
        'away': []
      };

      var spreadMovement = {
        'home': [],
        'away': []
      }

      this.oddsForGame.forEach(element => {
        moneyLineMovement['home'].push({
          'y': element['homeTeamMoneyLine'],
          'x': this.getTimestamp(element['timestamp'])
        });

        moneyLineMovement['away'].push({
          'y': element['awayTeamMoneyLine'],
          'x': this.getTimestamp(element['timestamp'])
        });

        spreadMovement['home'].push({
          'y': element['homeTeamSpread'],
          'x': this.getTimestamp(element['timestamp'])
        });
        spreadMovement['away'].push({
          'y': element['awayTeamSpread'],
          'x': this.getTimestamp(element['timestamp'])
        });
      });

      this.moneyLineMovement = moneyLineMovement;
      this.spreadMovement = spreadMovement;
    });

    this.currentPlayer = this.userService.getSelectedUser();

    this.apiService.getUserStatsForTeam(this.currentPlayer, this.homeTeam, this.awayTeam).subscribe(data => {
      console.log("User stats for team: ", data);
      this.userStatsForTeam = data;
      this.yearsAvailable = Object.keys(data.homeTeamStats);
      var yearsAvailable = this.yearsAvailable;

      setTimeout(function() {
        var lastYear = yearsAvailable[yearsAvailable.length - 1];
        console.log("Last year: " + lastYear);
        var lastYearButton = document.getElementById("button"+lastYear);
        if (lastYearButton) {
          console.log("Last year: " + lastYear);
          lastYearButton.className = "selectedYear";
        }

        var elements = document.getElementsByClassName(lastYear);
        if (elements) {
          for (var i = 0; i < elements.length; i ++) {
            var element = elements[i] as HTMLElement;
            element.className = lastYear + " visible";
          }
        }
      }, 200);
    });
  }

  getTimestamp(epoch) {
    return new Date(epoch * 1000);
  }

  getMoneyLine(moneyLine) {
    if (Number(moneyLine) > 0) {
      return "+" + moneyLine;
    } else {
      return moneyLine;
    }
  }

  ngOnInit(): void {
  }

  toggleMoneyLine() {
    var spreadSelector = document.getElementById('spreadSelector');
    if (spreadSelector) {
      spreadSelector.className = "notSelectedOdds";
    }

    var spreadChart = document.getElementById('spreadChart');
    if (spreadChart) {
      spreadChart.className = "hidden";
    }

    var moneyLineSelector = document.getElementById('moneyLineSelector');
    if (moneyLineSelector) {
      moneyLineSelector.className = "selectedOdds";
    }

    var moneyLineChart = document.getElementById('moneyLineChart');
    if (moneyLineChart) {
      moneyLineChart.className = "visible";
    }
  }

  toggleSpread() {
    var spreadSelector = document.getElementById('spreadSelector');
    if (spreadSelector) {
      spreadSelector.className = "selectedOdds";
    }

    var spreadChart = document.getElementById('spreadChart');
    if (spreadChart) {
      spreadChart.className = "visible";
    }

    var moneyLineSelector = document.getElementById('moneyLineSelector');
    if (moneyLineSelector) {
      moneyLineSelector.className = "notSelectedOdds";
    }

    var moneyLineChart = document.getElementById('moneyLineChart');
    if (moneyLineChart) {
      moneyLineChart.className = "hidden";
    }
  }

  toggleYear(year) {
    console.log("Toggling year: " + year);
    var elements = document.getElementsByClassName(year);
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        var element = elements[i] as HTMLElement;
        //element.style.display = "block";
        element.className = year + " visible";
      }

      var buttonElement = document.getElementById("button" + year);
      if (buttonElement) {
        buttonElement.className = "selectedYear";
      }

      for (let i = 0; i < this.yearsAvailable.length; i ++) {
        if (this.yearsAvailable[i] !== year) {
          console.log("Hiding year: " + this.yearsAvailable[i]);
          var elements = document.getElementsByClassName(this.yearsAvailable[i]);
          if (elements) {
            for (let x = 0; x < elements.length; x ++) {
              var element = elements[x] as HTMLElement;
              element.className = this.yearsAvailable[i] + " hidden";
            }
          }

          var buttonElement = document.getElementById("button" + this.yearsAvailable[i]);
          if (buttonElement) {
            buttonElement.className = "notSelectedYear";
          }
        }
      }
    }
  }

}
