<div class="container">
    <div class="rank">
        {{rank}}
        <div class='difference'>
            <div class='downwardDiv' *ngIf="movedDown">
                <mat-icon class="downward">arrow_downward</mat-icon>
                <div class="differenceNum">
                    {{difference}}
                </div>
            </div>
            <div class='upwardDiv' *ngIf="movedUp">
                <mat-icon  class="upward">arrow_upward</mat-icon>
                <div class="differenceNum">
                    {{difference}}
                </div>
            </div>
            <div class='stayedSameDiv' *ngIf="stayedSame">
                --
            </div>
        </div>
    </div>
    <div class="picture">
        <img mat-card-avatar class="playerPic" src="{{image}}">
    </div>
    <div class="name">{{name}}</div>
    <div class="wins">{{wins}} <br> Wins</div>
    <div class="losses">{{losses}}<br> Losses</div>
    <div class="percent" *ngIf="percent">{{percent}}%</div>
    <div class="percent" *ngIf="!percent">---</div>
  </div>