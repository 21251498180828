<div class="container" id={{gameId}}>
    <div class="homeTeam" id="{{homeTeam}}" (click)="selectHomeTeam()">
      <div class="homeTeamLogo">
        <img class="teamLogo" src="{{homeTeamLogo}}">
      </div>
      <div class="homeTeamName">{{homeTeamName}}</div>
      <div class="homeTeamBetting">{{homeTeamSpread}} ({{homeTeamMoneyLine}})</div>
    </div>
    <div class="awayTeam" id="{{awayTeam}}" (click)="selectAwayTeam()">
      <div class="awayTeamLogo">
        <img class="teamLogo" src="{{awayTeamLogo}}">
      </div>
      <div class="awayTeamName">{{awayTeamName}}</div>
      <div class="awayTeamBetting">{{awayTeamSpread}} ({{awayTeamMoneyLine}})</div>
    </div>
    <div class="vs">vs.</div>
    <div class="info">
        <a (click)="detailView()">INFO</a>
    </div>
</div>