import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component'
import { StandingsPageComponent } from './components/standings-page/standings-page.component';
import { ResultsPageComponent } from './components/results-page/results-page.component';

const routes: Routes = [
  {path: "", component: HomePageComponent },
  {path: "standings", component: StandingsPageComponent },
  {path: "results", component: ResultsPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
